@import '../../styles/mixins.scss';
.container {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    cursor: pointer;
}

.titleSection {
    @include flex(row, space-between, center);
    font-family: aktiv-grotesk, sans-serif;
    padding: 40px;
    border: 3px solid #2D71C7;
    cursor: pointer;
    &>span {
        @include font(2.25rem, bold, #2D71C7, 1.33);
        padding-right: 42px;
        font-style: normal;
    }
}

.contentSection {
    margin-top: 40px;
    cursor: default;
    * {
        font-size: 1.5rem;
        line-height: 1.5;
        font-family: aktiv-grotesk, sans-serif;
        word-break: break-word;
    }
}

.icon {
    width: 40px;
}

@include small-laptop-screen {
    .titleSection {
        padding: 30px;
        &>span {
            font-size: 2rem;
            line-height: 1.5;
            padding-right: 32px;
        }
    }
    .contentSection {
        margin-top: 30px;
        * {
            font-size: 1.25rem;
        }
    }
    .icon {
        width: 30px;
    }
}

@include tablet-screen {
    .container {
        margin-bottom: 24px;
    }
    .titleSection {
        padding: 24px;
        &>span {
            font-size: 1.5rem;
            padding-right: 24px;
        }
    }
    .contentSection {
        margin-top: 20px;
        * {
            font-size: 1.125rem;
        }
    }
    .icon {
        width: 24px;
    }
}

@include mobile-screen {
    .titleSection {
        border-width: 2px;
        padding: 16px;
        &>span {
            font-size: 1.25rem;
            padding-right: 16px;
        }
    }
    .contentSection {
        margin-top: 16px;
    }
    .icon {
        width: 16px;
    }
}