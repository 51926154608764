@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
.link {
    @include font(1.125rem, bold);
    letter-spacing: 0.02em;
    text-decoration: none;
    transition: color 300ms;
    @media (min-width: 1200px) {
        margin-left: 40px;
    }
}

.root {
    @include flex(row, space-between, center);
    font-family: aktiv-grotesk, sans-serif;
    text-transform: uppercase;
    padding: 30px 50px 0;
    position: absolute;
    width: 100%;
    box-sizing: border-box;
    z-index: 3;
    .logo {
        // transform: scale(1.02) translateX(-25px);
        width: 193px;
        height: 89px;
    }
    .navbar {
        @include flex(row, space-around, center);
        list-style-type: none;
        text-decoration: none;
        .inactive {
            @extend .link;
            color: $white;
            &:hover {
                color: $yellow;
            }
        }
        .active {
            @extend .link;
            color: $yellow;
        }
    }
    .mobileNavbar {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        list-style-type: none;
        text-decoration: none;
        position: fixed;
        margin: 0;
        top: 0;
        left: 0;
        background: $blue;
        padding: 0;
        .inactive {
            @extend .link;
            color: $white;
        }
        .active {
            @extend .link;
            color: $yellow;
        }
        &>li {
            margin: 0;
            padding: 0;
            width: 100%;
            &>a {
                color: #2D71C7;
                display: block;
                padding: 20px;
                text-align: center;
            }
        }
    }
    .burgerIcon {
        @include set-size(32px, auto);
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 20px;
        z-index: 1;
    }
    .closeIcon {
        @include set-size(30px, auto);
        position: fixed;
        top: 30px;
        right: 20px;
        z-index: 1;
    }
}

@include tablet-screen {
    .root {
        padding: 0 20px;
        .logo {
            transform: scale(0.34) translateX(-185px);
        }
    }
}