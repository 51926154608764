@import '../../styles/mixins.scss';
.container {
    @include flex(row, center, center);
    @include set-size(100%, 580px);
    background-color: rgba(0, 0, 0, 0.2);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding: 20px;
}

.title {
    @include font(6.25rem, 700, #FFFFFF, 1);
    font-family: "aktiv-grotesk-condensed", sans-serif;
    text-transform: uppercase;
    font-style: normal;
    margin: 0;
    word-break: break-word;
}

.subTitle {
    @include font(2.25rem, normal, #FFFFFF, 1.33);
    font-style: normal;
    margin: 46px 0 0;
    word-break: break-word;
}

@include medium-laptop-screen {
    .container {
        @include set-size(100%, 400px);
    }
    .title {
        @include font(5rem, 700, #FFFFFF, 1.33);
    }
    .subTitle {
        @include font(1.75rem, normal, #FFFFFF, 1.5);
    }
}
@include tablet-screen {
    .container {
        @include set-size(100%, 190px);
    }
    .title {
        @include font(3rem, 700, #FFFFFF, 1.5);
    }
    .subTitle {
        @include font(1.25rem, normal, #FFFFFF, 1.5);
    }
    .cardWrapper {
        @include font(1.5, 500, #FFFFFF, 1.5);
    }
}
@include mobile-screen {
    .title {
        @include font(2rem, 700, #FFFFFF, 1.5);
        margin-top: 2rem;
    }
}