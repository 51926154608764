@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
.carouselContainer {
    position: relative;
}

.logo {
    position: absolute;
    top: 50px;
    left: 50px;
    z-index: 2;
}

.information {
    @include flex(column, center, center);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 50px;
    top: 150px;
    z-index: 2;
    color: white;
    padding: 0 20px;
}

.pictureContainer {
    @include set-size(100%, 700px);
}

.picture {
    @include set-size(100%, 100%);
    @include flex(column, center);
    background-color: #AFAFAF;
    /* fallback color */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    color: white;
}

.title {
    @include font(6.25rem, 700, #FFFFFF, 1);
    @include flex(row, center);
    font-family: "aktiv-grotesk-condensed", sans-serif;
    padding: 0;
    margin: 0 0 3rem;
}

.titleLeft {
    font-family: "aktiv-grotesk-condensed", sans-serif;
    padding-right: 16px;
}

.titleRight {
    padding: 0 1rem;
    color: black;
    background-color: #FFCC18;
}

.subTitle {
    @include font(1.375rem, 400, #FFFFFF, 1.45);
    text-align: center;
    letter-spacing: 0.02em;
    max-width: 860px;
    margin: 0 auto 2rem;
}

.description {
    @include font(1.375rem, 400, $yellow, 1.45);
    max-width: 860px;
    margin: 0 auto;
    text-align: center;
}

.london {
    background-image: url("./images/london.jpg");
}

.bucha {
    background-image: url("./images/bucha.jpg");
}

.subway {
    background-image: url("./images/subway.jpg");
}

.tank {
    background-image: url("./images/tank.jpg");
}

.house {
    background-image: url("./images/house.jpg");
}

.sliderControl {
    position: absolute;
    bottom: 24px;
    left: 50%;
    transform: translateX(-50%);
    ul {
        position: relative;
        top: -10px;
        display: flex;
        margin: 0px;
        padding: 0px;
        list-style-type: none;
        li {
            button {
                cursor: pointer;
                opacity: 1;
                background: transparent;
                border: none;
                fill: white;
            }
        }
    }
}

.notActivePage {
    opacity: 0.5;
}

.titleLink {
    font-family: "aktiv-grotesk-condensed", sans-serif;
    text-decoration: none;
    color: black;
}

@include medium-laptop-screen {
    .pictureContainer {
        height: 600px;
    }
}

@include small-laptop-screen {
    .title {
        font-size: 3.5rem;
        line-height: 1;
    }
    .subTitle {
        font-size: 1.25rem;
    }
    .description {
        font-size: 1.25rem;
    }
}

@include tablet-screen {
    .pictureContainer {
        height: 400px;
    }
    .logo {
        top: 0;
        left: 20px;
    }
    .information {
        top: 70px;
    }
    .logoSvg {
        width: 80px;
    }
    .title {
        font-size: 2rem;
        line-height: 1;
        margin-bottom: 2rem;
    }
    .titleLeft {
        padding: 0 8px 0 0;
    }
    .titleRight {
        padding-left: .5rem;
        padding-right: .5rem
    }
    .subTitle {
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    .description {
        font-size: 1rem;
    }
    .london {
        background-image: url("./images/london-mob.jpg");
    }
    .bucha {
        background-image: url("./images/bucha-mob.jpg");
    }
    .subway {
        background-image: url("./images/subway-mob.jpg");
    }
    .tank {
        background-image: url("./images/tank-mob.jpg");
    }
    .house {
        background-image: url("./images/house-mob.jpg");
    }
}

@include mobile-screen {
    .title {
        font-size: 2.2rem;
        line-height: 1;
        margin-bottom: 1.5rem;
    }
    .subTitle {
        font-size: 0.857rem;
    }
    .description {
        font-size: 0.857rem;
    }
    .sliderControl {
        bottom: 0;
    }
}