$mobile-screen: 480px;
$tablet-screen: 768px;
$small-laptop-screen: 1280px;
$medium-laptop-screen: 1440px;

@mixin font($size: 18px, $weight: normal, $color: black, $lineHeight: 1.5) {
  color: $color;
  font-size: $size;
  font-weight: $weight;
  line-height: $lineHeight;
}

@mixin set-size($width: 0, $height: 0) {
  height: $height;
  width: $width;
}

@mixin flex($direction: row, $justify: flex-start, $align: stretch) {
  align-items: $align;
  display: flex;
  flex-direction: $direction;
  justify-content: $justify;
}

@mixin mobile-screen {
  @media screen and (max-width: $mobile-screen) {
    @content;
  }
}

@mixin tablet-screen {
  @media screen and (max-width: $tablet-screen) {
    @content;
  }
}

@mixin small-laptop-screen {
  @media screen and (max-width: $small-laptop-screen) {
    @content;
  }
}

@mixin medium-laptop-screen {
  @media screen and (max-width: $medium-laptop-screen) {
    @content;
  }
}