@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';
.wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: $yellow;
    min-height: 184px;
    padding: 50px;
    @include font(1.375rem, normal, #000000, 1.5);
}

.footerInfo {
    flex: 2 1 0;
}

.networking {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 2 1;
}

.emailSection {
    @include flex(column);
    text-align: end;
    flex: 1 1 0;
    align-items: flex-start;
}

.iconContainer {
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.socialMediaLink {
    display: flex;
    padding-top: 2px;
    margin-left: 8px;
}

@include tablet-screen {
    .wrapper {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 20px 60px 20px 20px;
        font-size: 12px;
        line-height: 20px;
    }
    .footerInfo {
        align-items: center;
    }
    .networking {
        align-items: center;
        margin: 30px 0;
    }
    .emailSection {
        align-items: flex-start;
    }
}

@include mobile-screen {
    .wrapper {
        padding: 20px;
    }
}