@import '../../styles/mixins.scss';
.subtitle {
    @include font(3rem, bold, #000000, 1.33);
    font-style: normal;
    text-transform: uppercase;
    margin: 50px 0;
    word-break: break-word;
}
@include medium-laptop-screen {
  .subtitle {
    font-size: 2.5rem;
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

@include small-laptop-screen {
  .subtitle {
    font-size: 2.25rem;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@include tablet-screen {
  .subtitle {
    font-size: 2rem;
    margin-top: 24px;
    margin-bottom: 24px;
  }
}

@include mobile-screen {
  .subtitle {
    font-size: 1.5rem;
    line-height: 1.5;
  }
}
