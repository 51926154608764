@import "../../styles/mixins.scss";
@import "../../styles/colors.scss";

.wrapper {
  @include flex;
  gap: 20px;
  flex-wrap: wrap;
  padding: 80px 120px;
  margin: 0 auto;
  max-width: 1478px;

  .cardWrapper {
    min-width: 400px;
    height: 276px;
    flex: 1 1 0;

    .card {
      @include set-size(100%, 100%);
      @include flex(column, flex-end);
      background-color: $yellow; /* fallback color */
      background-position: center;
      background-size: cover;
      color: white;

      .redirectLinkWrapper {
        justify-content: space-between;
        font-size: 1.25rem;
        line-height: 1.33;
        width: 68.6%;

        .icon {
          width: 28px;
        }
      }
    }
  }
}

.aid {
  background-image: url("./images/aid.jpg");
}

.contact {
  background-image: url("./images/contact.jpg");
}

.events {
  background-image: url("./images/events.jpg");
}

.legal {
  background-image: url("./images/legal.jpg");
}

.mfa {
  background-image: url("./images/mfa.jpg");
}

.resources {
  background-image: url("./images/resources.jpg");
}

.volunteer {
  background-image: url("./images/volunteer.jpg");
}

.donate {
  background-image: url("./images/donate.jpg");
}

@include medium-laptop-screen {
  .wrapper {
    padding: 50px; 
  }
}
@include tablet-screen { 
  .wrapper {
    padding: 20px; 

    .cardWrapper { 
      height: 130px;
      min-width: 100%;

      .card {
        .redirectLinkWrapper {
          font-size: 1rem;
          line-height: 1.33;
          width: 80%;

          .icon {
            width: 24px;
          }
        }
      }
    }
  }

  .redirectLinkWrapper {
    font-size: 1.125rem;
    line-height: 1.33;
    padding-bottom: 10px;
    padding-top: 10px;
  }
}
