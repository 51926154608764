body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1, h2, h3, h4 {
  font-family: aktiv-grotesk, sans-serif;
  font-weight: bold;
}

p {
  font-family: aktiv-grotesk, sans-serif;
}

a {
  color: #2D71C7;
}