@import '../../styles/mixins.scss';
@import '../../styles/colors.scss';

.redirectLink {
    @include font(1.875rem, 700, #FFFFFF, 1.5);
    @include flex(row, center, center);
    padding: 25px 28px;
    text-transform: uppercase;
    letter-spacing: 0.01em;
    background-color: $blue;
    max-width: 80%;
    text-decoration: none;
    transition: background-color 300ms;

    &:hover {
        background-color: $blue-hover;
    }
}

@include tablet-screen { 
    .redirectLink {
        @include font(18px, 700, #FFFFFF);
        padding: 16px;
    }

}