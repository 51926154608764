@import 'mixins.scss';

*, *::before, *::after {
  box-sizing: border-box;
  font-family: aktiv-grotesk, sans-serif;
}
html {
  font-size: 16px;

  @media (max-width: 1700px) {
    font-size: 15px;
  }
  @include medium-laptop-screen {
    font-size: 14px;
  }
}
