@import '../styles/colors.scss';
@import '../styles/mixins.scss';
.container {
    @include flex(column, center, center);
}

.contentContainer {
    max-width: 900px;
    margin: 44px 30px 158px;
}

.description {
    @include font(2.25rem, normal, #000000, 1.33);
    font-style: normal;
}

.qrImage {
    width: 200px;
    height: 200px;
}

.eventsImg {
    max-width: 100%;
}

.ourImpactCarousel {
    width: 100%;
    height: 500px;
}

.sectionDescription,
.sectionTitle {
    @include font(1.5rem, normal, #000000, 1.5);
    font-family: aktiv-grotesk, sans-serif;
    font-style: normal;
    margin: 5px 0;
    &:not(:last-child) {
        a {
            text-decoration: none;
            color: black;
        }
    }
}

.subheader {
    @include font(2.25rem, normal, #000000, 1.33);
    margin-bottom: 70px;
}

.list {
    margin: 0;
    font-size: 24px;
    li {
        padding: 10px 0;
        li {
            list-style-type: none;
        }
    }
}

.redirectLink {
    margin: 46px 0;
}

.legalBackground {
    background-image: url("../assets/legal-background.png");
}

.humanitarianAidBackground {
    background-image: url("../assets/humanitarian-aid.png");
}

.contactsBackground {
    background-image: url("../assets/resources.jpeg");
}

.resourcesBackground {
    background-image: url("../assets/resources.png");
}

.eventsBackground {
    background-image: url("../assets/events-header.jpg");
}

.aboutBackground {
    background-image: url("../assets/MFA_1.png");
}

.humanitarianAidButton {
    width: 480px;
}

.resourcesButton,
.eventsButton {
    width: 340px;
}

.donate {
    .sectionDescription {
        @include font(1.5rem,
        normal,
        #000000,
        1.5);
        font-family: aktiv-grotesk, sans-serif;
        font-style: normal;
        margin-bottom: 46px;
    }
    .subTitle {
        margin-top: 110px;
    }
    .background {
        background-image: url("../assets/donate.jpeg");
    }
    .redirectLink {
        width: 324px;
    }
}

@include medium-laptop-screen {
    .contentContainer {
        margin: 40px 24px 100px;
    }
    .description {
        font-size: 2rem;
    }
    .subheader {
        font-size: 2rem;
        margin-bottom: 50px;
    }
}

@include small-laptop-screen {
    .contentContainer {
        margin: 30px 20px 60px;
    }
    .description {
        font-size: 1.5rem;
        line-height: 1.5;
    }
    .sectionDescription,
    .sectionTitle {
        font-size: 1.25rem;
    }
    .redirectLink {
        margin-top: 24px;
        margin-bottom: 24px;
    }
    .subheader {
        font-size: 1.75rem;
        margin-bottom: 40px;
    }
}

@include tablet-screen {
    .contentContainer {
        margin-bottom: 30px;
    }
    .subheader {
        font-size: 1.5rem;
        line-height: 1.5;
        margin-bottom: 24px;
    }
    .donate {
        .sectionDescription {
            @include font(1.125rem,
            normal,
            #000000,
            1.5);
            margin-bottom: 24px;
        }
        .subTitle {
            margin-top: 60px;
        }
        .redirectLink {
            width: auto;
        }
    }
}

.learnMore {
    background-color: #fff !important;
    color: $blue !important;
    margin-left: 35px !important;
    &:hover {
        background-color: #fff !important;
        opacity: 0.8;
    }
}

.eventsButtonWrapper {
    display: flex;
    margin-top: 60px;
}

.carouselImageWrapper {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .carouselImage {
        max-height: 100%;
        max-width: 100%;
        width: auto !important;
        height: auto;
    }
}

ul:global(.control-dots) {
    width: 50% !important;
}

div:global(.carousel-slider) {
    display: flex;
    justify-content: center;
}

.partnersWrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

@include mobile-screen {
    .humanitarianAidButton,
    .resourcesButton,
    .eventsButton {
        width: auto;
    }
    .subheader {
        font-size: 1.25rem;
    }
    ul:global(.control-dots) {
        width: 80% !important;
    }
    .carouselImageWrapper {
        height: 300px;
    }
    .ourImpactCarousel {
        height: 300px;
    }
    .partnersWrapper {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }
}